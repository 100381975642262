<template>
    <div :class="[typeForm, 'filter-item']">
        <modal-field
            v-if="template === 'filter-site'"
            :label="name"
        >
            <multiselect
                :ref="typeForm"
                group-label="owner"
                :group-select="false"
                group-values="sites"
                :options="options[optionsName] || []"
                :class="className"
                :placeholder="placeholder"
                :value="modelValue"
                :disabled-selected-all="true"
                :multiple="true"
                @input="$emit('update', $event)"
            >
            </multiselect>
        </modal-field>

        <modal-field
            v-else-if="template === 'carrier_service_ids'"
            :label="name"
        >
            <multiselect
                :ref="typeForm"
                group-label="name"
                :group-select="false"
                group-values="items"
                :options="options[optionsName] || []"
                :class="className"
                :label="label"
                :placeholder="placeholder"
                :track-by="trackBy"
                :value="modelValue"
                :multiple="true"
                @close="clearSearchOnClose(typeForm)"
                @input="$emit('update', $event)"
            >
            </multiselect>
        </modal-field>

        <!-- <modal-field
            v-else-if="template === 'transport_mode_from_source'"
            :label="name"
        >
            <multiselect
                :branding="branding"
                group-label="name"
                :group-select="false"
                group-values="items"
                :options="options[optionsName]"
                :class="className"
                :placeholder="placeholder"
                :value="modelValue"
                :multiple="true"
                @input="$emit('update', $event)"
            >
            </multiselect>
        </modal-field> -->

        <modal-field
            v-else-if="typeForm === 'date'"
            :label="name"
        >
            <date-range-picker
                :loaded-range="loadedRange"
                :createStartDate="modelValue.startDate"
                :createEndDate="modelValue.endDate"
                :full-value="modelValue"
                :trigger="datesTrigger"
                :remove-all-item="removeAllItem"
                :remove-default-value="removeDefaultValue"
                :createdComponents="createdComponents"
                :initAll="initAll"
                :clearable="name != 'Date'"
                full-list
                @change="$emit('update', $event)"
            />
        </modal-field>

        <modal-field v-else-if="typeForm === 'multi-select-colors'" :label="name">
            <multiselect
                :ref="typeForm"
                :options="options[optionsName] || []"
                :label="label"
                :track-by="trackBy"
                multiple
                is-colors
                :value="modelValue"
                @close="clearSearchOnClose(typeForm)"
                @input="$emit('update', $event)"
            >
                <template slot="option" slot-scope="{ option }">
                    <fa
                        :class="getStatusColor(option.value)"
                        :icon="getStatusIcon(option.value)"
                        class="text-xs"
                        transform="shrink-5"
                    />
                    {{ option.label }}
                </template>
            </multiselect>
        </modal-field>

        <modal-field
            v-else-if="typeForm === 'select'"
            :label="name"
        >
            <multiselect
                :options="options[optionsName] || []"
                class="relative"
                :value="modelValue"
                :label="selectLabel"
                :multiple="false"
                is-clear
                @input="$emit('update', $event)"
            >
                <template slot="clear">
                    <div
                        @mousedown.prevent.stop="$emit('update', null)"
                        class="absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                        v-show="modelValue != null"
                    >
                        <svg-importer icon-name="icons/close" />
                    </div>
                </template>
            </multiselect>
        </modal-field>

        <modal-field v-else-if="typeForm === 'input'" :label="name">
            <input
                :value="modelValue"
                type="text"
                @change="$emit('update', $event.target.value)"
                :placeholder="placeholder"
            />
        </modal-field>

        <modal-field v-else-if="template === 'filter-site-client'" :label="name">
            <multiselect
                :ref="typeForm"
                :class="{'no-branding': adminRoute }"
                :options="options[optionsName] || []"
                :label="label"
                :track-by="trackBy"
                multiple
                :disabled-select-all="true"
                :value="modelValue"
                @close="clearSearchOnClose(typeForm)"
                @input="$emit('update', $event)"
            >
            </multiselect>
        </modal-field>

        <modal-field v-else-if="typeForm === 'multi-select'" :label="name">
            <multiselect
                :ref="typeForm"
                :options="options[optionsName] || []"
                :label="label"
                :track-by="trackBy"
                multiple
                :value="modelValue"
                :max-selectable="maxSelectable"
                :disabled-select-all="disabledSelectAll"
                @close="clearSearchOnClose(typeForm)"
                @input="$emit('update', $event)"
            >
            </multiselect>
        </modal-field>

        <modal-field
            v-else-if="typeForm === 'switch'"
            :label="name"
            class="mr-5"
        >
            <v-checkbox
                class="mt-2"
                :value="modelValue"
                @click="$emit('update', $event)"
            />
        </modal-field>

        <modal-field
            v-else
            class="h-full"
            body-class="h-full"
        >
            <checkbox
                class="h-full"
                :checked="modelValue"
                :disabled="disabled"
                :loading="filterSavedConsginmentsLoading"
                @input="$emit('update', $event)"
            >
                {{ name }}
            </checkbox>
        </modal-field>
    </div>
</template>

<script>
import DateRangePicker from '~/components/DateRangePicker';
import VCheckbox from '~/components/VCheckbox'
import FormMultiselect from '~/components/FormMultiselect'

export default {
    components: {
        DateRangePicker,
        VCheckbox,
        FormMultiselect
    },

    props: [
        'modelValue',
        'template',
        'adminRoute',
        'user',
        'options',
        'typeForm',
        'branding',
        'groupLabel',
        'groupSelect',
        'groupValues',
        'className',
        'label',
        'placeholder',
        'trackBy',
        'multiple',
        'organizations',
        'name',
        'loadedRange',
        'datesTrigger',
        'filter',
        'filterModel',
        'optionsName',
        'removeAllItem',
        'removeDefaultValue',
        'owner_list',
        'createdComponents',
        'initAll',
        'filterSavedConsginmentsLoading',
        'maxSelectable',
        'disabled',
        'disabledSelectAll',
    ],

    model: {
        prop: 'modelValue',
        event: 'update'
    },

    computed: {
        selectLabel() {
            const options = this.options[this.optionsName];

            if (!options?.length) {
                return '';
            }

            if (options[0].name) {
                return 'name';
            }

            if (options[0].label) {
                return 'label';
            }

            return '';
        }
    },
    methods: {
        clearSearchOnClose(templateName) {
            this.$refs[templateName].searchValue = ''
        },
    },
}
</script>
